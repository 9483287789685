<template>
  <div class="p-grid p-mt-3">
    <div class="p-col-12">
      <SODForm ref="sodForm" />
    </div>
    <div class="p-col-12 p-my-3">
      <StepNav
        :prev="false"
        :nextEnabled="!v$.$invalid && isChannelMember !== 'loading'"
        :nextLoading="isChannelMember === 'loading'"
        @nextStep="handleNextPage"
      />
      <div class="p-d-flex p-jc-end p-mt-3">
        <small>Fields marked with * are required</small>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, provide, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useVuelidate } from '@vuelidate/core'
import SODForm from '@/components/reporting/SODForm.vue'
import StepNav from '@/components/custom/StepNav.vue'

export default {
  name: 'SODCreate',
  emits: ['next-page'],
  components: { SODForm, StepNav },
  setup(_, { emit }) {
    const route = useRoute()
    const toast = useToast()
    // data
    const sodForm = ref(null)

    const isFormSubmitted = ref(false)

    const isChannelMember = computed(() => sodForm.value?.isChannelMember)

    // injected/provided properties
    const clearReports = inject('clearReports')
    provide('isFormSubmitted', isFormSubmitted)

    const handleNextPage = () => {
      if (!isChannelMember.value) {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'You must be a member of the selected channel. Please inform your SM to fix this error.',
          life: 10000
        })
        return
      }
      clearReports()
      sodForm.value.submitReport()
      emit('next-page', route.path)
    }

    // Validation stuff
    const v$ = useVuelidate()

    return {
      sodForm,
      clearReports,
      handleNextPage,
      isFormSubmitted,
      isChannelMember,
      v$
    }
  }
}
</script>

<style scoped>
</style>
