<template>
  <ReportForm :id="$attrs.id" :header="header" :allowToggle="!v$.$invalid">
    <div class="p-formgrid p-grid p-fluid">
      <!-- Slack Channel -->
      <LabeledField
        v-if="channelOptions?.length > 1"
        class="p-col-12 p-md-6"
        label="Slack Channel*"
        :autoLayout="false"
        :showHelp="v$.channel.$invalid && v$.channel.$dirty"
        :errors="v$.channel.$silentErrors"
      >
        <Dropdown
          placeholder="Select slack channel"
          v-model="formData.channel"
          optionLabel="channel"
          :options="channelOptions.sort()"
          :loading="loading"
          @hide="v$.channel.$touch()"
          @change="handleCheckSlackMembership($event)"
        />
      </LabeledField>

      <!-- Top Priority -->
      <LabeledField
        label="What is the most important thing you are focusing on today and why is it so important?*"
        class="p-col-12 p-mb-3"
        :autoLayout="false"
        :showHelp="v$.topPriority.$invalid && v$.topPriority.$dirty"
        :errors="v$.topPriority.$silentErrors"
      >
        <Editor
          editorStyle="height: 200px"
          placeholder="What is the most important thing you are focusing on today and why is it so important?"
          :formats="['bold', 'list']"
          v-model="formData.topPriority"
          @click="v$.topPriority.$touch()"
          @paste="$event.preventDefault()"
        >
          <template v-slot:toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
          </template>
        </Editor>
      </LabeledField>

      <!-- Tasks for Today -->
      <LabeledField
        label="Tasks for Today*"
        class="p-col-12"
        :autoLayout="false"
        :showHelp="v$.tasksForToday.$invalid && v$.tasksForToday.$dirty"
        :errors="v$.tasksForToday.$silentErrors"
      >
        <Editor
          editorStyle="height: 200px"
          placeholder="Following SoD guidelines, provide the customer / cost center with a summary of your day"
          v-model="formData.tasksForToday"
          :formats="['bold', 'list']"
          @click="v$.tasksForToday.$touch()"
        >
          <template v-slot:toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
          </template>
        </Editor>
      </LabeledField>
    </div>
  </ReportForm>
</template>

<script>
import { computed, inject, reactive, ref, watch } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'

import ReportForm from '@/components/custom/ReportForm.vue'
import useReport from '../../hooks/useReport'
import useSlack from '../../hooks/useSlack'
import { handleHttpError, tagStripper } from '../../utils/utilities'

/**
 * @typedef FormData
 * @property {?import('../../hooks/useReport').SlackChannel} channel
 * @property {!string} type
 * @property {?string} topPriority
 * @property {?string} tasksForToday
 */

export default {
  name: 'SODForm',
  emits: ['formDelete'],
  components: { ReportForm },
  setup(_, { emit }) {
    const confirm = useConfirm()
    const toast = useToast()
    const { error: useReportError, fetchSlackChannels, loading } = useReport()
    const { checkSlackMembership, error: useSlackError } = useSlack()

    const isChannelMember = ref('loading')
    const isPanelCollapsed = ref(false)
    const channelOptions = ref([])

    /** @type {FormData} */
    const formData = reactive({
      channel: null,
      type: 'SOD',
      topPriority: null,
      tasksForToday: null
    })

    watch(useSlackError, (value) => {
      if (value) {
        handleHttpError(value, toast, 'Failed to check channel membership.')
        isChannelMember.value = null
        formData.channel = null
      }
    })

    watch(useReportError, (value) => {
      if (value) {
        handleHttpError(value, toast, 'Failed to fetch slack channels.')
        channelOptions.value = []
      }
    })

    // created lifecycle hook
    ;(async () => {
      const resp = await fetchSlackChannels()
      isChannelMember.value = true
      if (!useSlackError.value && resp?.data?.length) {
        const channels = resp.data
        channelOptions.value = channels
        if (channels.length === 1) {
          formData.channel = channels[0]
        }
      }
    })()

    // injected properties
    const addReport = inject('addReport')
    const isFormSubmitted = inject('isFormSubmitted')

    // computed properties
    const header = computed(() => {
      const _header = { title: 'SOD Report', tag: null }

      if (formData.channel && isChannelMember.value !== 'loading') {
        _header.tag = {
          value: formData.channel.channel,
          severity: 'info'
        }

        if (isChannelMember.value === false) {
          _header.tag = {
            value: `${formData.channel.channel} - not a member`,
            severity: 'danger'
          }
        }
      }

      return _header
    })

    // methods
    const handleCheckSlackMembership = async (event) => {
      isChannelMember.value = 'loading'
      const { value } = event
      const resp = await checkSlackMembership(value)
      if (!useSlackError.value) {
        isChannelMember.value = resp.data?.is_member
      }
    }

    const handleDeleteSODForm = () => {
      confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-circle',
        // position: 'right',
        accept: () => emit('formDelete'),
        reject: () => confirm.close()
      })
    }

    const submitReport = () => {
      addReport({ ...formData, channel: formData.channel?.channel, costCenterId: formData.channel?.cost_center_id })
    }

    // Validation stuff
    const computedFormData = computed(() => ({
      channel: formData.channel,
      topPriority: tagStripper(formData.topPriority),
      tasksForToday: tagStripper(formData.tasksForToday)
    }))

    const rules = computed(() => {
      const value = {
        channel: { required: false },
        topPriority: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(1200)
        },
        tasksForToday: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(1200)
        }
      }

      if (channelOptions.value?.length > 1) {
        value.channel = { required }
      }
      return value
    })

    const v$ = useVuelidate(rules, computedFormData)

    return {
      channelOptions,
      computedFormData,
      handleCheckSlackMembership,
      handleDeleteSODForm,
      header,
      isChannelMember,
      isFormSubmitted,
      isPanelCollapsed,
      loading,
      submitReport,
      formData,
      v$
    }
  }
}
</script>
